import React from 'react'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import update from 'immutability-helper'
import GroupIcon from '@material-ui/icons/Group'
import KitchenIcon from '@material-ui/icons/Kitchen'
import AddToQueueIcon from '@material-ui/icons/AddToQueue'
import { Link, useHistory } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import { useDispatch } from 'react-redux'
import { NavItem } from './Nav'
import { signOutUser } from '../../../store/actions/user'

const style = {
	width: 400,
}

export default function MenuFilters() {
	const dispatch = useDispatch()

	const history = useHistory()

	const onSignOut = () => {
		dispatch(signOutUser()).then(() => {
			history.push('/login')
		})
	}

	{
		const [navs, setNavs] = React.useState([
			{
				id: 1,
				name: 'Kitchen',
				icon: <KitchenIcon color="secondary" />,
				url: '/kitchen',
				tooltip: 'Plan your week',
			},
			{
				id: 2,
				name: 'Community',
				icon: <GroupIcon />,
				url: '/community',
				tooltip: 'Community',
			},
			{
				id: 3,
				name: 'Create',
				icon: <AddToQueueIcon color="secondary" />,
				url: '/recipe/create',
				tooltip: 'Create a new recipe',
			},
		])

		const moveNav = React.useCallback(
			(dragIndex, hoverIndex) => {
				const dragNav = navs[dragIndex]
				setNavs(
					update(navs, {
						$splice: [
							[dragIndex, 1],
							[hoverIndex, 0, dragNav],
						],
					})
				)
			},
			[navs]
		)

		const renderNav = (nav, index) =>
			nav.url ? (
				<NavItem
					key={nav.id}
					index={index}
					id={nav.id}
					icon={
						<Link to={nav.url}>
							<Tooltip title={nav.name} placement="bottom-start">
								<div
									key={nav.id}
									button
									color="secondary"
									style={{
										display: 'flex',
										flexDirection: 'row',
										padding: '1px',
									}}
								>
									<Button color="secondary" startIcon={nav.icon} size="small">
										<Typography color="secondary">
											{' '}
											<b>{nav.name}</b>
										</Typography>
									</Button>
								</div>
							</Tooltip>
						</Link>
					}
					moveNav={moveNav}
				/>
			) : (
				<NavItem
					key={nav.id}
					index={index}
					id={nav.id}
					icon={
						<div onClick={onSignOut}>
							<Tooltip title={nav.name} placement="bottom-start">
								<div
									key={nav.id}
									button
									color="secondary"
									style={{
										display: 'flex',
										flexDirection: 'row',
										marginTop: '5px',
									}}
								>
									<Button color="secondary">{nav.icon}</Button>
								</div>
							</Tooltip>
						</div>
					}
					moveNav={moveNav}
				/>
			)

		return (
		// <Container style={{ marginTop:'20px', display:'flex', flexDirection:'row', marginBottom:'30px', backgroundColor:'#f2f6ef'}}>
			<Container
				style={{
					marginTop: '20px',
					display: 'flex',
					flexDirection: 'row',
					marginBottom: '20px',
				}}
			>
				{navs.map((n, i) => renderNav(n, i))}
			</Container>
		)
	}
}
