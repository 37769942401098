import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import { openMenu } from '../../store/actions/menu'
import '../../utils/styles/scss/Header.scss'

import NavButton from './navigation/Header'

export default function Header() {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  return (
    <div style={{ marginLeft: '-1%', marginRight: '-1%' }}>
      <div className="d-flex justify-content-between align-items-center py-lg-4 py-2">
        <Grid
          container
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {user && (
            <Grid item xs={11} style={{ display: 'flex' }}>
              <NavButton />{' '}
            </Grid>
          )}

          {!user && (
            <Grid
              container
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '10px',
              }}
            >
              <Grid item>
                <Link to="/">
                  <img
                    id="logo"
                    src="/media/logo.png"
                    width={160}
                    height="auto"
                    alt="app logo"
                    style={{ cursor: 'pointer' }}
                  />
                </Link>
              </Grid>
              <Grid item spacing={1} style={{ marginTop: '5px' }}>
                <Link to="/login">
                  <Button variant="outline" size="sm">
                    Login
                  </Button>
                </Link>
                <Link to="/register">
                  <Button variant="outline-dark" size="sm">
                    Register
                  </Button>
                </Link>
              </Grid>
            </Grid>
          )}

          {user && (
            <Grid item xs={1}>
              <IconButton
                style={{ float: 'right' }}
                onClick={() => dispatch(openMenu())}
              >
                <RestaurantMenuIcon fontSize="large" color="secondary" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  )
}
