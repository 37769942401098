/* eslint-disable */
import React, { Fragment } from 'react'
import { Collapse, Grid, IconButton } from '@material-ui/core'
import { DndProvider } from 'react-dnd'
import MenuFilters from './MenuFilters'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { Link } from 'react-router-dom'
function NavButton() {
  const [expanded, setExpanded] = React.useState(true)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Fragment>
      <Grid container style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Grid item xs={12}>
          <Link to="/">
            <img
              src="/media/logo.png"
              width={190}
              height="auto"
              alt="app logo"
              style={{ cursor: 'pointer' }}
            />
          </Link>
        </Grid>
        <Grid xs={12} container>
          {/* <Grid item xs={1}>
          {expanded!=true &&
          (<ArrowDropDownIcon 
          fontSize='large' 
          color="secondary" 
          onClick={handleExpandClick} 
          style={{ cursor: 'pointer'}}
          />)}
        </Grid> */}
          <Grid item xs={1}></Grid>
          <Grid
            item
            xs={11}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Collapse
              in={expanded}
              timeout="auto"
              unmountOnExit
              style={{ justifyContent: 'center' }}
            >
              <DndProvider backend={HTML5Backend}>
                <MenuFilters />
              </DndProvider>
            </Collapse>
          </Grid>
          {/* <Grid item xs={1}>
          {expanded!=false &&
          (<ArrowDropUpIcon 
          fontSize='large' 
          color="secondary" 
          onClick={handleExpandClick} 
          style={{ cursor: 'pointer'}}
          />)}
        </Grid>   */}
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default NavButton
