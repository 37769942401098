import React, { lazy } from 'react'
import { BrowserRouter, withRouter } from 'react-router-dom'
import { useRouteMatch } from 'react-router-dom'
import { Container } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { MuiThemeProvider, CssBaseline } from '@material-ui/core'
import Routes from './app/Routes'
import Header from './app/components/Header'

import Footer from './app/components/Footer'
import Loading from './app/components/Loading'
import UserVerificationAlert from './app/components/UserVerificationAlert'
import { QueryClient, QueryClientProvider } from 'react-query'

import theme from './theme'
import GlobalStyles from './GlobalStyles'
import backgroundShape from './shape.svg'

const RegisterPage = lazy(() => import('./app/pages/RegisterPage'))
const LoginPage = lazy(() => import('./app/pages/LoginPage'))
const HomePage = lazy(() => import('./app/pages/HomePage'))
const Menu = lazy(() => import('./app/components/Menu'))
const ResetPage = lazy(() => import('./app/pages/ResetPage'))
const BlogPage = lazy(() => import('./app/pages/BlogPage'))
const Membership = lazy(() => import('./app/pages/Membership'))
const ProfilePage = lazy(() => import('./app/pages/ProfilePage'))

const Member = withRouter((props) => (
  <div>
    {props.location.pathname === '/' ? (
      <HomePage />
    ) : props.location.pathname === '/membership' ? (
      <Membership />
    ) : props.location.pathname === '/register' ? (
      <RegisterPage />
    ) : props.location.pathname === '/goveggie' ? (
      <BlogPage />
    ) : useRouteMatch('/reset/:token') &&
      useRouteMatch('/reset/:token').params.token ? (
      <ResetPage />
    ) : (
      props.location.pathname != ('/' | '/register') && <LoginPage />
    )}
  </div>
))

const NonMember = withRouter((props) => (
  <div>
    {props.location.pathname === '/' ? (
      <HomePage />
    ) : props.location.pathname === '/membership' ? (
      <Membership />
    ) : props.location.pathname === '/goveggie' ? (
      <BlogPage />
    ) : props.location.pathname === '/profile' ? (
      <ProfilePage />
    ) : useRouteMatch('/reset/:token') &&
      useRouteMatch('/reset/:token').params.token ? (
      <ResetPage />
    ) : (
      props.location.pathname != ('/' | '/register') && <HomePage />
    )}
  </div>
))

const queryClient = new QueryClient()

function App() {
  const user = useSelector((state) => state.user)

  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <React.Suspense fallback={() => <Loading />}>
          <BrowserRouter>
            <Container
              style={{
                width: '100vw', // Ensure it spans the full width of the viewport
                maxWidth: '100%', // Override Material-UI Container's maxWidth property
                minHeight: '100vh', // Make sure it covers the full viewport height
                height: '100%',
                backgroundImage: `url(${backgroundShape})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              {user &&
                !user.verified &&
                process.env.REACT_APP_EMAIL_VERIFICATION === 'true' && (
                  <UserVerificationAlert />
                )}
              <Header />
              {user && <Menu />}
              {user && user.membership ? (
                <Routes />
              ) : user ? (
                <NonMember />
              ) : (
                <Member />
              )}
              <Footer />
            </Container>
          </BrowserRouter>
        </React.Suspense>
      </MuiThemeProvider>
    </QueryClientProvider>
  )
}

export default App
